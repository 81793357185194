import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import './App.css';

import Snowfall from 'react-snowfall';
import { AnimatedText } from './AnimatedText';



const StyledForm = styled.form`
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInput = styled.input`
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: auto;
`;

const StyledButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const PlayButton = styled.button`
background-color: #007bff;
color: white;
padding: 10px 15px;
border: none;
border-radius: 4px;
cursor: pointer;

&:hover {
  background-color: #0056b3;
}
`;

function App() {
  
  const [isReady, setIsReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const passwordInput = useRef()
  const adminKey = process.env.REACT_APP_ADMIN_KEY
  let audio = new Audio("/christmassong.mp3")

  const startAudio = () => {
    audio.play();
  }

  useEffect(() =>{
    isReady && startAudio()
  },[isReady])

  useEffect(() => {
    // Definieren Sie Ihren festgelegten keycode

    // URL-Parameter auslesen
    const queryParams = new URLSearchParams(window.location.search);
    const keyValue = queryParams.get('key');
    console.log(keyValue, process.env.REACT_APP_ADMIN_KEY)
    // Überprüfen, ob der URL-Parameter mit dem festgelegten keycode übereinstimmt
    if (keyValue === adminKey) {
      setIsLoggedIn(true);
    }
  }, []);

  const checkPassword = (e) =>{
    e.preventDefault()
    console.log(passwordInput.current.value, process.env.REACT_APP_ADMIN_KEY)
    if(passwordInput.current.value === adminKey){
      setIsReady(true);
      setIsLoggedIn(true);
      startAudio();
    } 
  }

  return (
    <div className="App">
      {isReady ? 
      <>
      <Snowfall/>
      <AnimatedText/>
      </>
      : isLoggedIn ? <PlayButton onClick={()=>setIsReady(true)}>start</PlayButton> :
      <StyledForm onSubmit={checkPassword}>
      <StyledInput type='password' ref={passwordInput} />
      <StyledButton type='submit'>Send</StyledButton>
    </StyledForm>
      
    }
      </div>
  );
}

export default App;
