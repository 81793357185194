import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const WORDS_TO_ANIMATE = [
  { text: 'Liebe Leana', timeout: 3000 },
  { text: 'Frohe Weihnachten', timeout: 3500 },
  { text: 'und herzlich willkommen', timeout: 3500 },
  { text: 'auf deiner eigenen Website', timeout: 3500 },
  { text: 'www.leana.dance', timeout: 4000 },
  { text: 'die können wir bald anfangen zu befüllen' , timeout: 4000 },
  { text: 'Ich liebe Dich ❤️' , timeout: 4000 },
  { text: '' , timeout: 3500 }
];

const Text = styled.h1`
font-family: 'Indie Flower';
color: white;
  &.fade-enter {
    opacity: 0;
  }
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
  }
  &.fade-exit {
    opacity: 1;
  }
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
  }
`;

export const AnimatedText = () => {
  const [currentItem, setCurrentItem] = useState(WORDS_TO_ANIMATE[0]);
  const [index, setIndex] = useState(1);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (index < WORDS_TO_ANIMATE.length) {
      const { timeout } = WORDS_TO_ANIMATE[index];
      const timer = setTimeout(() => {
        setShow(false);
        const nextItemTimer = setTimeout(() => {
          setShow(true);
          setIndex(index + 1);
          setCurrentItem(WORDS_TO_ANIMATE[index]);
        }, 500); // Warten auf das Ende des Ausblendens

        return () => clearTimeout(nextItemTimer);
      }, timeout - 500); // Startet das Ausblenden 500ms vor dem Timeout

      return () => clearTimeout(timer);
    }
  }, [index, show]);

  return (
    <TransitionGroup>
      {show && (
        <CSSTransition
          key={currentItem.text}
          timeout={500}
          classNames="fade"
        >
          <Text>{currentItem.text}</Text>
        </CSSTransition>
      )}
    </TransitionGroup>
  );
};

